<template>
  <v-card-text>
    <v-card-text v-if="value.checked === 'false'" class="pt-0 pb-0">
      <v-textarea v-model="value.cartridgeFilter.comment" placeholder="Commentaire" rows="1" auto-grow filled/>
      <AppImageInput v-model="value.cartridgeFilter.file" placeholder="Ajouter une image"/>
    </v-card-text>
    <v-card-text v-if="value.checked === 'true'" class="pt-0 pb-0">
      <AppCheckbox v-model="value.filterStock.checked"
                   label="Vérification stock de filtres additionnels chez le client si possible *" yes_label="Existants"
                   no_label="Absents" :required="true"/>
      <AppRadioYesNo v-if="value.filterStock.checked" v-model="value.filterStock.hasFilterStock"
                     label="Vérification stock de filtres additionnels chez le client si possible" yes_label="Existants"
                     no_label="Absents"/>
      <v-card-text v-if="value.filterStock.checked && value.filterStock.hasFilterStock === 'true'" class="pt-0">
        <v-text-field v-model="value.filterStock.inStock" hide-details single-line type="number"
                      label="Quantité présente dans le stock de filtres" filled/>
      </v-card-text>
    </v-card-text>
  </v-card-text>
</template>

<script>
import AppRadioYesNo from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/RadioYesNo";
import AppImageInput from "@/components/App/ImageInput.vue";
import AppCheckbox from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/Checkbox";

export default {
  name: "CartridgeFilterControl",
  components: {
    AppRadioYesNo,
    AppImageInput,
    AppCheckbox,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
}
</script>