<template>
  <v-card-text>
    <v-row class="pl-3 pr-3 mb-n4" justify="space-between">
      <p class="mb-0 mr-4">{{ label }}
        <v-dialog v-if="popUpInformation" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="primary" v-bind="attrs" v-on="on">
              <v-icon>fa fa-circle-info</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-img :src="image_url"></v-img>
          </v-card>
        </v-dialog>
      </p>
      <v-radio-group v-model="checked" row class="ma-0 mt-n1">
        <v-radio label="Oui" value="true"></v-radio>
        <v-radio label="Non" value="false"></v-radio>
        <v-radio label="Non Applicable" value="NonApplicable"></v-radio>
      </v-radio-group>
    </v-row>

    <v-card-text v-if="checked === 'true'">
      <v-row class="mt-2 pl-3 pr-3 mb-n4" justify="space-between">
        <p class="mb-0 mr-4">Conforme</p>
        <v-radio-group v-model="compliant" row class="ma-0 mt-n1">
          <v-radio label="Oui" value="true"></v-radio>
          <v-radio label="Non" value="false"></v-radio>
        </v-radio-group>
      </v-row>
    </v-card-text>

    <v-card-text v-if="checked === 'true' && compliant === 'false'" class="pt-0 pb-0">
      <v-textarea class="pb-4" v-model="comment" placeholder="Commentaire" rows="1" filled auto-grow hide-details/>
      <AppImageInput v-model="image" placeholder="Ajouter une image"/>
    </v-card-text>
  </v-card-text>
</template>


<script>
import AppImageInput from "@/components/App/ImageInput.vue";

export default {
  name: "AppRadioYesNoNoApplicableConformityCheck",
  components: {AppImageInput},
  props: {
    label: {
      type: String,
      required: true,
    },
    popUpInformation: {
      type: Boolean,
      default: false,
      required: false,
    },
    image_url: {
      type: String,
      default: "",
      required: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      checked: "false",
      compliant: "true",
      comment: "",
      image: null,
    };
  },
  mounted() {
    this.checked = this.value.checked;
    this.compliant = this.value.compliant;
  },
  watch: {
    checked(newValue) {
      this.$emit("input", {
        checked: newValue,
        compliant: this.compliant,
        comment: this.comment,
        file: this.image,
      });
    },
    compliant(newValue) {
      this.$emit("input", {
        checked: this.checked,
        compliant: newValue,
        comment: this.comment,
        file: this.image,
      });
    },
    comment(newValue) {
      this.$emit("input", {
        checked: this.checked,
        compliant: this.compliant,
        comment: newValue,
        file: this.image,
      });
    },
    image(newValue) {
      this.$emit("input", {
        checked: this.checked,
        compliant: this.compliant,
        comment: this.comment,
        file: newValue,
      });
    },
  },
};
</script>
