<template>
    <v-card class="mt-5">
        <AppVCardTitle label="Vue générale" />
        <AppVTextWithButton label="Photo de la cuve *" image_url="/img/implementedForms/62b97ed722d02/tank.png" />
        <v-card-text class="pt-0">
            <v-file-input prepend-icon="" prepend-inner-icon="mdi-paperclip" v-model="tank_image" multiple placeholder="Image de la cuve" accept=".png, .jpg, .jpeg" required filled hide-details @change="displayImagesPreview(tank_image, 'tank')" :rules="[v => !!v]"/>
        </v-card-text>

        <AppVTextWithButton label="Photo du distributeur *" image_url="/img/implementedForms/62b97ed722d02/distrubutorControl.png" />
        <v-card-text class="pt-0">
            <v-file-input
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                v-model="distributor_image"
                placeholder="Image du distributeur"
                accept=".png, .jpg, .jpeg"
                required
                filled
                hide-details
                multiple
                @change="displayImagesPreview(distributor_image, 'distributor')"
                :rules="[v => !!v]"
            />
        </v-card-text>
    </v-card>
</template>

<script>
import AppVCardTitle from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/VCardTitle";
import AppVTextWithButton from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/VTextWithButton";
import imageCompression from "browser-image-compression";

export default {
    name: "ControlOfTheElectricalCabinet",
    components: {
        AppVCardTitle,
        AppVTextWithButton,
    },
    data() {
        return {
            tank_image: null,
            distributor_image: null,
        };
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    methods: {
        async displayImagesPreview(imgs, context) {
          switch (context) {
                    case "tank":
                        this.value.tank = [];
                        break;
                    case "distributor":
                        this.value.distributor = [];
                        break;
                }
          imgs.forEach(async (img) =>{
            const toBase64 = (file) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                });

            const options = {
                maxSizeMB: 0.2,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            };

            const compressedFile = await imageCompression(img, options);
            const file = new File([compressedFile], compressedFile.name, {
                type: compressedFile.type,
                lastModified: new Date().getTime(),
            });

            switch (context) {
                case "tank":
                    this.value.tank.push(await toBase64(file));
                    break;
                case "distributor":
                  this.value.distributor.push(await toBase64(file));
                  break;
            }
          })
            this.$emit("input", this.value);
        },
    },
};
</script>
