<template>
    <v-card-text>
        <v-checkbox v-model="checked" class="mb-n6 mt-n6" :label="label" :rules="[v => (v || !required)]"/>
        <v-card-text v-if="checked">
            <v-row class="mt-2 pl-3 pr-3 mb-n4" justify="space-between">
                <p class="mb-0 mr-4">Conforme</p>
                <v-radio-group v-model="compliant" row class="ma-0 mt-n1">
                    <v-radio label="Oui" value="true"></v-radio>
                    <v-radio label="Non" value="false"></v-radio>
                </v-radio-group>
            </v-row>
        </v-card-text>

        <v-card-text v-if="checked && withDate">
            <v-row class="mt-2 pl-3 pr-3 mb-n4" justify="space-between">
                <p class="mb-0 mr-4">Date lisible</p>
                <v-radio-group v-model="readableDate" row class="ma-0 mt-n1">
                    <v-radio label="Oui" :value="true"></v-radio>
                    <v-radio label="Non" :value="false"></v-radio>
                </v-radio-group>
            </v-row>
            <v-row class="mt-2 pl-3 pr-3 mb-n4" justify="space-between">
                <v-menu
                    v-if="readableDate"
                    ref="date_menu"
                    v-model="date_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="date_formatted"
                            label="Date de validité *"
                            persistent-hint
                            @blur="date = parseDate(date_formatted)"
                            v-on="on"
                        />
                    </template>
                    <v-date-picker
                        v-model="date"
                        :type="'month'"
                        no-title @input="date_menu = false"
                        locale="fr-fr"
                    />
                </v-menu>
            </v-row>
        </v-card-text>


        <v-card-text v-if="checked && compliant === 'false'" class="pt-0 pb-0">
            <v-textarea class="pb-4" v-model="comment" placeholder="Commentaire" rows="1" filled auto-grow hide-details/>
            <AppImageInput  v-model="image" placeholder="Ajouter une image"/>
        </v-card-text>
    </v-card-text>
</template>

<script>
import AppImageInput from "@/components/App/ImageInput.vue";

export default {
    name: "AppCheckboxWithConformityForm",
    components: {
        AppImageInput,
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            type: Object,
            required: true,
        },
        readableDate: {
            type: Boolean,
            required: false,
            default: true,
        },
        withDate: {
            type: Boolean,
            required: false,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
            checked: false,
            compliant: "true",
            comment: "",
            image: null,
            date: new Date().toISOString().substring(0, 10),
            date_formatted: this.formatDate(new Date().toISOString().substring(0, 10)),
            date_menu: false,
        };
    },
    mounted() {
        this.checked = this.value.checked;
        this.compliant = this.value.compliant;
    },
    watch: {
        checked(newValue) {
            this.$emit("input", {
                checked: newValue,
                compliant: this.compliant,
                comment: this.comment,
                validityDate: this.date_formatted,
                readableValidityDate: this.readableDate,
                file: this.image,
            });
        },
        compliant(newValue) {
            this.$emit("input", {
                checked: this.checked,
                compliant: newValue,
                comment: this.comment,
                validityDate: this.date_formatted,
                readableValidityDate: this.readableDate,
                file: this.image,
            });
        },
        comment(newValue) {
            this.$emit("input", {
                checked: this.checked,
                compliant: this.compliant,
                comment: newValue,
                validityDate: this.date_formatted,
                readableValidityDate: this.readableDate,
                file: this.image,
            });
        },
        image(newValue) {
            this.$emit("input", {
                checked: this.checked,
                compliant: this.compliant,
                comment: this.comment,
                validityDate: this.date_formatted,
                readableValidityDate: this.readableDate,
                file: newValue,
            });
        },
        date() {
            this.date_formatted = this.formatDate(this.date)
            this.$emit("input", {
                checked: this.checked,
                compliant: this.compliant,
                comment: this.comment,
                validityDate: this.date_formatted,
                readableValidityDate: this.readableDate,
                file: this.image,
            });
        },
        readableDate() {
            this.$emit("input", {
                checked: this.checked,
                compliant: this.compliant,
                comment: this.comment,
                validityDate: this.date_formatted,
                readableValidityDate: this.readableDate,
                file: this.image,
            });
        }
    },
    methods :{
        formatDate(date) {
          if (!date) return null
          const [year, month, day] = date.split('-')
          const quarter = Math.floor((month - 1) / 3) + 1;
          return `Q${quarter}/${year}`;
        },
        parseDate(date) {
          if (!date) return null
          const match = date.match(/^Q(\d)\/(\d{4})$/);
          if (match) {
            const quarter = parseInt(match[1]);
            const year = parseInt(match[2]);

            return `${year}-${quarter}-`
          }
        }
    }
};
</script>
