<template>
  <v-card-text>
    <v-row class="pl-3 pr-3 mb-n4" justify="space-between">
      <p class="mb-0 mr-4">{{ label }}
        <v-dialog v-if="popUpInformation" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="primary" v-bind="attrs" v-on="on">
              <v-icon>fa fa-circle-info</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-img :src="image_url"></v-img>
          </v-card>
        </v-dialog>
      </p>
      <v-radio-group v-model="checked" row class="ma-0 mt-n1" >
        <v-radio label="Oui" value="true"></v-radio>
        <v-radio label="Non" value="false"></v-radio>
        <v-radio label="Non Applicable" value="NonApplicable"></v-radio>
      </v-radio-group>

    </v-row>
  </v-card-text>
</template>


<script>
export default {
  name: "AppRadioYesNoNonApplicable",
  props: {
    label: {
      type: String,
      required: true,
    },
    popUpInformation : {
      type: Boolean,
      default: false,
      required: false,
    },
    image_url: {
      type: String,
      default: "",
      required: false,
    },
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      checked: "false",
    };
  },
  mounted() {
    this.checked = this.value;
  },
  watch: {
    checked(newValue) {
      this.$emit("input", newValue);
    },
  },
};
</script>
