<template>
    <v-card-text class="pt-0 px-0">
        <v-file-input prepend-icon="" :rules="rules" prepend-inner-icon="mdi-paperclip" v-model="image" :placeholder="placeholder" multiple accept=".png, .jpg, .jpeg" required filled hide-details @change="displayImagesPreview(image)" />
    </v-card-text>
</template>

<script>
import imageCompression from "browser-image-compression";

export default {
    name: "AppImageInput",
    data() {
        return {
            image: null,
        };
    },
    props: {
        value: {},
        placeholder: {
            type: String,
            required: true,
        },
        rules: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    methods: {
        async displayImagesPreview(imgs) {
            if (imgs === null) {
                this.$emit("input", []);
                return;
            }

            const toBase64 = (file) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                });

            const options = {
                maxSizeMB: 0.01,
                maxWidthOrHeight: 1080,
                useWebWorker: true,
            };
            let res = []
            imgs.forEach(async (img) => {
              const compressedFile = await imageCompression(img, options);
              let file = new File([compressedFile], compressedFile.name, {
                  type: compressedFile.type,
                  lastModified: new Date().getTime(),
              });
              console.log('base64')
              res.push( await toBase64(file))
            })
          this.$emit("input", res);
        },
    },
};
</script>
