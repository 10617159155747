<template>
    <v-card class="mt-5">
        <AppVCardTitleWithButton label="Contrôle armoire électrique" image_url="/img/implementedForms/62b97ed722d02/distrubutorControl.png" />
        <AppCheckbox v-model="value.controlTriggeringOfSafety.checked" label="Contrôle du bon déclenchement des protections des composants électriques *" :required="true"/>
        <AppRadioYesNo v-if="value.controlTriggeringOfSafety.checked" v-model="value.controlTriggeringOfSafety.differential" label="Différentiels" />
        <AppRadioYesNoNonApplicable v-if="value.controlTriggeringOfSafety.checked" v-model="value.controlTriggeringOfSafety.emergencyStop" label="Arrêt d’urgence" />
        <v-card-text v-if="value.controlTriggeringOfSafety.checked && value.controlTriggeringOfSafety.emergencyStop === 'true'" class="pt-0 pb-0 error--text">
            <p>Vérifier auprès du client avant de déclencher l’arrêt d’urgence pour ne pas causer de problème avec l’automate.</p>
        </v-card-text>
        <v-card-text v-if="value.controlTriggeringOfSafety.checked" class="pt-0 pb-0">
            <v-textarea v-model="value.controlTriggeringOfSafetyComment" placeholder="Commentaire" rows="1" auto-grow filled></v-textarea>
            <AppImageInput v-model="image" placeholder="Ajouter une image" />
        </v-card-text>

        <!--<AppCheckboxWithConformityForm v-model="value.verificationOfElectricalEquipment" label="Vérification des équipements électriques (bonne fixation et oxydation notamment) *" :required="true"/>-->
        <AppRadioYesNoNoApplicableConformityCheck v-model="value.verificationOfElectricalEquipment" label="Vérification des équipements électriques (bonne fixation et oxydation notamment) *"/>

       <!-- <AppCheckboxWithConformityForm v-model="value.checkingForLeaks" label="Vérification de la détection de fuites - Bouton test, puis Bouton Arrêt Vibreur *" :required="true"/>-->
      <AppRadioYesNoNoApplicableConformityCheck v-model="value.checkingForLeaks" label="Vérification de la détection de fuites - Bouton test, puis Bouton Arrêt Vibreur *"/>

      <!--<AppCheckboxWithConformityForm v-model="value.electricalCableControl" label="Contrôle visuel des câbles électriques, dépoussiérage, presse étoupes et boîtiers de raccordements *" :required="true"/>-->
      <AppRadioYesNoNoApplicableConformityCheck v-model="value.electricalCableControl" label="Contrôle visuel des câbles électriques, dépoussiérage, presse étoupes et boîtiers de raccordements *"/>

        <!--<AppCheckboxWithConformityForm v-model="value.tighteningTheConnections" label="Serrage des connections *" :required="true"/>-->
      <AppRadioYesNoNoApplicableConformityCheck v-model="value.tighteningTheConnections" label="Serrage des connections *"/>

    </v-card>
</template>

<script>
import AppCheckbox from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/Checkbox";
import AppCheckboxWithConformityForm from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/CheckboxWithConformityForm";
import AppRadioYesNo from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/RadioYesNo";
import AppVCardTitleWithButton from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/VCardTitleWithButton";
import AppImageInput from "@/components/App/ImageInput";
import AppRadioYesNoNonApplicable
  from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/RadioYesNoNoApplicable.vue";
import AppRadioYesNoNoApplicableConformityCheck
  from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/RadioYesNoNoApplicableConformityCheck.vue";


export default {
    name: "ControlOfTheElectricalCabinet",
    components: {
      AppRadioYesNoNoApplicableConformityCheck,
      AppRadioYesNoNonApplicable,
        AppVCardTitleWithButton,
        AppCheckbox,
        AppCheckboxWithConformityForm,
        AppRadioYesNo,
        AppImageInput,
    },
    data() {
        return {
            image: null,
        };
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    watch: {
        image(newValue) {
            this.value.controlTriggeringOfSafetyFile = newValue;
        },
    },
};
</script>
